import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: -20px;
    font-size: 20px;
  }

  textarea {
    padding: 5px 5px 5px 15px;
    border: 1px dashed lightgray;
  }
`;

const TextArea = ({
  label = null,
  textAreaRef,
  width = "900px",
  fontSize = "16px",
  marginTop = "20px",
  minHeight = "100px",
  defaultValue,
  placeholder = "type sth special...",
  resize = "none",
  required = false,
  onChange = () => {},
}) => {
  return (
    <Wrapper>
      {label && (
        <label>
          {label}:{required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <textarea
        ref={textAreaRef}
        style={{
          width: width,
          fontSize: fontSize,
          marginTop: marginTop,
          minHeight: minHeight,
          resize: resize,
        }}
        defaultValue={defaultValue || ""}
        required
        placeholder={placeholder}
        aria-required={required}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default TextArea;
